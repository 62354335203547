/* eslint-disable react/no-danger */
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import type { HeadFC } from "gatsby";
import type { QueryReturnHead } from "@typing/types";

import "@stylesPages/About.scss";

import { Footer, Header, SEO } from "@components";

const AboutPage = () => {
  const { t } = useTranslation("about");

  return (
    <>
      <Header absolute />
      <section className="about-page">
        <div className="about-page__container">
          <h1 className="about-page__title">{t("title")}</h1>
          <p className="about-page__text" dangerouslySetInnerHTML={{ __html: t("description") }} />
          <div className="about-page__numbers">
            <article className="about-page__card">
              <h1 className="about-page__card-title">7</h1>
              <p className="about-page__card-text">{t("years")}</p>
            </article>
            <article className="about-page__card">
              <h1 className="about-page__card-title">+5</h1>
              <p className="about-page__card-text">{t("countries")}</p>
            </article>
            <article className="about-page__card">
              <h1 className="about-page__card-title">+1.000</h1>
              <p className="about-page__card-text">{t("instruments")}</p>
            </article>
            <article className="about-page__card">
              <h1 className="about-page__card-title">+5,000</h1>
              <p className="about-page__card-text">{t("clients")}</p>
            </article>
          </div>
        </div>
        <div className="about-page__banner">
          <div className="about-page__container">
            <h2 className="about-page__title about-page__title--banner">{t("title-two")}</h2>
            <p className="about-page__text about-page__text--banner">
              {t("text-two")}
            </p>
            <p className="about-page__text about-page__text--banner">
              <span>{t("subtitle")}</span>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;

export const Head: HeadFC<QueryReturnHead> = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "about"));

  const { "title-seo": titleSeo } = about ? JSON.parse(about.node.data) : {
    "title-seo": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
